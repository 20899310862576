export const GrowthMarketing = {
    partner: [
      {
        "orgName": "FSMSDC - Florida State Minority Supplier Development Council",
        "link": "https://fsmsdc.org/",
        "name":"Beatrice Louissaint",
        "email":"admin@mbdamiamicenter.com",
        "phone": "",
        "comments":"Must be majority minority owned.",
        "keywords":["Procurement", "Connections", "Certifications", "Minority", "Expansion", "Business Development", "New Business", "Corporate Sector", "Access to Capital"]
      },
      {
        "orgName": "MBDA - Minority Business Development Agency",
        "link": "https://www.mbda.gov/businesscenters/miami/",
        "name":"Marie Hyppolite",
        "email":"admin@mbdamiamicenter.com",
        "phone": "",
        "comments":"Must be majority minority owned.",
        "keywords":["Guidance", "Connections", "Certifications", "Minority", "Financing", "Access to Capital", "Lenders", "Loans", "Retain Jobs"]
      },
      {
        "orgName": "MBDA - Minority Business Development Agency",
        "link": "http://www.mgillonline.com/MBDA",
        "name":"Marie Gill",
        "email":"marie@mgillonline.com",
        "phone": "",
        "comments":"Focused on minority owned small businesses",
        "keywords":["Procurement", "Guidance", "Connections", "Certifications", "Minority", "Expansion", "Business Development", "New Business", "International", "Trade"]
      },
      {
        "orgName": "Miami-Dade County Small Business Department",
        "link": "http://www.miamidade.gov/smallbusiness/library/flyers/sba-coronavirus-relief.pdf",
        "name":"Lawanda Wright",
        "email":"sbdmail@miamidade.gov",
        "phone": "",
        "comments":"Must be small businesses within Miami-Dade County, with 100 employees or less. Process: Businesses have the option of leaving a voicemail when they call our main line (305-375-3111) or sending an email to sbdmail@miamidade.gov  with their contact information.  These two point of contact will be checked every 30 minutes and will be assigned to staff.  Assisting firms with the application are staff’s main priority and will be returning calls typically within the same day but no later than 24 hours.",
        "keywords":["Procurement", "Guidance", "Connections", "Certifications", "Small Business", "Expansion", "New Business", "Bonding", "Contracts"]
      },
      {
        "orgName": "Miami-Dade County Public Schools",
        "link": "http://procurement.dadeschools.net/",
        "name":"Jennifer Andreu",
        "email":"jandreu@dadeschools.net",
        "phone": "",
        "comments":"Focused on small businesses, minority owned businesses, emphasis on inclusion",
        "keywords":["Connections", "Certifications", "Procurement", "Minority", "Small Business", "Inclusion", "New Business"]
      },
      {
        "orgName": "Miami Bayside Foundation",
        "link": "https://miamibaysidefoundation.org/",
        "name": "Joann Milord",
        "email": "joann@mbf.miami",
        "phone": "",
        "comments":"",
        "keywords": ["Haitian Creole Speaking", "Spanish Speaking", "Micro Loans", "Loan Packaging", "Growth Marketing", "Procurement"]
      },
      {
        "orgName": "City of North Miami",
        "link": "https://www.northmiamifl.gov/",
        "name": "Linda Julien",
        "email": "ljulien@northmiamifl.gov",
        "phone": "",
        "comments":"Business must be located in the City of North Miami",
        "keywords": ["Procurement", "Guidance", "Connections", "Business Development", "New Business"]
      },
     
    ],
  }
  
  export default GrowthMarketing
  